<section class="flex flex-col h-full">
  <div
    class="w-[460px] h-full"
    [ngClass]="{ 'w-screen': mobileBrowserChecker.isMobileDevice() }"
  >
    @switch (drawerMode) {
      @case ('connectionInfo') {
        @defer (on immediate) {
          <app-connection-info-drawer
            [isArchitect]="isAdminView"
            (toggleDrawer)="toggleDrawer()"
          />
        }
      }
      @case ('stationInfo') {
        @defer (on immediate) {
          <app-station-info-drawer
            [isOutsideView]="isOutsideView"
            [isUsedFromMap]="isUsedFromMap"
            [isAdminView]="isAdminView"
            (toggleDrawer)="toggleDrawer()"
          />
        }
      }
      @case ('stationGroupInfo') {
        @defer (on immediate) {
          <app-station-group-info-drawer
            [isAdminView]="isAdminView"
            (toggleDrawer)="toggleDrawer()"
          />
        }
      }
      @case ('containerInfo') {
        @defer (on immediate) {
          <app-container-info-drawer
            [isArchitect]="isAdminView"
            (toggleDrawer)="toggleDrawer()"
          />
        }
      }
      @case ('fieldSetting') {
        @defer (on immediate) {
          <app-setting-drawer />
        }
      }
    }
  </div>
</section>
